import {
  Button,
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
  Switch,
} from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Descriptions, Select } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import dayjs from 'dayjs';

type PropsType = {
  record: any;
  recordReducer: IRecordReducer;
  alertMessage: (params: { body: string; type: string }) => void;
  isDisabled?: boolean;
};

const CreateOneTouchSwitchOrder: FunctionComponent<PropsType> = (props) => {
  const { record, alertMessage } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(false);

  const [contact, setContact] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [address, setAddress] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [otsOrder, setOtsOrder] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [installWorkOrder, setInstallWorkOrder] = React.useState<any>(undefined);
  const [otsProviderDirectory, setOtsProviderDirectory] = React.useState<any>(undefined);
  const [voiceConfiguration, setVoiceConfiguration] = React.useState<any>(undefined);

  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);

  const [currentStep, setCurrentStep] = React.useState<string>('step-one');

  const [stepData, setStepData] = React.useState<any>({
    accountNumber: undefined,
    switchInternet: true,
    switchVoice: false,
    authorizeSwitchRequest: false,
    surname: undefined,
    email: undefined,
    phoneNumberToPort: undefined,
  });

  const [isPolling, setIsPolling] = React.useState(true); // State to control polling

  useEffect(() => {
    fetchOtsOrder();
  }, []);

  useEffect(() => {
    if (!isPolling) return;

    const fetchResult = async () => {
      try {
        console.log('POLLING...', otsOrder?.id);
        // Simulate an API call
        if (otsOrder) {
          httpGet(
            `OrderModule/v2.0/records/OneTouchSwitch/${otsOrder?.id}?targetEntities=["ActivityLog"]`,
          )
            .then((res) => {
              // Check if the desired result is returned
              if (
                ['SWITCH_MATCH_REQUEST_FAILED', 'SWITCH_MATCH_REQUEST_CONFIRMED'].includes(
                  getProperty(res.data.data, 'Status'),
                )
              ) {
                setOtsOrder(res.data.data);
                setIsPolling(false); // Stop polling when the desired result is returned
              }
            })
            .catch((err) => {
              console.error('Error loading tab data:', err);
            });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchResult, 5000); // Poll every 5 seconds

    // Stop polling after 1 minute
    const timeoutId = setTimeout(() => {
      setIsPolling(false);
    }, 60000); // 1 minute

    return () => {
      clearInterval(intervalId); // Clean up interval
      clearTimeout(timeoutId); // Clean up timeout
    };
  }, [otsOrder, isPolling]); // Only rerun the effect if isPolling changes

  useEffect(() => {
    if (isDialogOpen) {
      fetchData();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    console.log(
      'Install work order is loaded',
      installWorkOrder,
      getFirstRelation(installWorkOrder, 'ServiceAppointment'),
    );

    if (installWorkOrder && !getFirstRelation(installWorkOrder, 'ServiceAppointment')) {
      console.log('NO APPOINTMENT ERROR');
      // setIsNextDisabled(true);
      setError('To proceed, please add an install date to the work order.');
    }
  }, [installWorkOrder]);

  useEffect(() => {
    if (currentStep === 'step-one') {
      console.log('stepData.otsProviderId', stepData.otsProviderId);
      if (!stepData.otsProviderId) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
    }
  }, [stepData.otsProviderId]);

  useEffect(() => {
    if (currentStep === 'step-two') {
      console.log('stepData.authorizeSwitchRequest', stepData.authorizeSwitchRequest);

      if (stepData.authorizeSwitchRequest) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    }
  }, [stepData.authorizeSwitchRequest]);

  const fetchOtsOrder = async () => {
    httpGet(
      `OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=OneTouchSwitch&nestedEntities=["ActivityLog"]`,
    )
      .then((res) => {
        if (res.data['OneTouchSwitch']?.dbRecords) {
          setOtsOrder(
            res.data['OneTouchSwitch']?.dbRecords?.find(
              (elem: any) => elem.id === 'b2831b9a-bee4-4df7-981c-029dd9a2202b',
            ),
          );
        }
      })
      .catch((err) => {
        console.error('Error loading tab data:', err);
      });
  };

  const fetchData = async () => {
    if (record) {
      httpGet(`OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=Contact`)
        .then((res) => {
          if (res.data['Contact']?.dbRecords) {
            setContact(res.data['Contact']?.dbRecords[0]);
          }
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(`OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=Address`)
        .then((res) => {
          if (res.data['Address']?.dbRecords) {
            setAddress(res.data['Address']?.dbRecords[0]);
          }
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=OrderItem&nestedEntities=["VoiceConfiguration"]`,
      )
        .then((res) => {
          if (res.data['OrderItem']?.dbRecords) {
            const voiceConfig = res.data['OrderItem']?.dbRecords.find(
              (elem: any) => elem['VoiceConfiguration']?.dbRecords,
            );
            if (voiceConfig) {
              setStepData({
                ...stepData,
                phoneNumberToPort: getProperty(voiceConfig[0], 'PhoneNumberToPort'),
              });
              setVoiceConfiguration(voiceConfig[0]);
            }
          }
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(`OrderModule/v1.0/totsco/requests/rcp-directory`)
        .then((res) => {
          setOtsProviderDirectory(
            res.data.data.list
              ?.find((elem: any) => elem.listType === 'RCPID')
              ?.identity?.sort((a: any, b: any) => a.name.localeCompare(b.name)),
          );
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `OrderModule/v1.0/db-associations/Order/${record.id}/one-relation?entity=WorkOrder&nestedEntities=["ServiceAppointment"]`,
      )
        .then((res) => {
          const workOrdersList = res.data['WorkOrder']?.dbRecords || [];

          const installWorkOrder = workOrdersList.find((wo: any) => wo.type === 'INSTALL');
          setInstallWorkOrder(installWorkOrder);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });
    }
  };

  function setupStep(newStep: string) {
    setCurrentStep(newStep);

    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        console.log('STEP 1', stepData);
        if (stepData.otsProviderId) {
          setIsNextDisabled(false);
        }
        break;
      case 1:
        console.log('STEP 2', stepData);
        if (!stepData.authorizeSwitchRequest) {
          setIsNextDisabled(true);
        } else {
          setIsNextDisabled(false);
        }

        break;
      case 2:
        console.log('STEP 3', stepData);
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    console.log('FINAL SUBMIT STEP');
    setError(undefined);
    setIsConfirmLoading(true);
    // Add additional data to the form data
    try {
      setIsConfirmLoading(false);
      setIsNextDisabled(true);

      // Test account number: 'SP-SI-240719-RWJBCD'

      const switchMatchRequesst: any = {
        addressId: address?.id,
        orderId: record.id,
        destinationIdentity: stepData.otsProviderId,
        destinationAccountNumber: stepData.accountNumber || null,
        destinationContact: {
          LastName: getProperty(contact, 'LastName'),
          Email: getProperty(contact, 'EmailAddress'),
        },
        services: [],
      };

      if (stepData.switchInternet) {
        switchMatchRequesst.services.push({
          serviceType: 'IAS',
          action: 'cease',
        });
      }

      if (stepData.switchVoice) {
        switchMatchRequesst.services.push({
          serviceType: 'Voice',
          action: 'port',
          serviceIdentifier: `${stepData.phoneNumberToPort}`,
        });
      }

      console.log('switchMatchRequesst: ', switchMatchRequesst);

      // Make API call for the switch match requesst

      const res = await httpPost(
        'OrderModule/v1.0/totsco/requests/switch-match-request',
        switchMatchRequesst,
      )
        .then((res) => {
          console.log('res: ', res);

          setOtsOrder(res.data.data);
          setIsPolling(true);
          setIsConfirmLoading(false);

          return res.data.data;
        })
        .catch((err) => {
          console.error('Error:', err);
        });

      console.log('res: ', res);

      setInterval(() => {}, 1000);
    } catch (err: any) {
      setIsConfirmLoading(false);
      console.error('Error:', err?.response?.data);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    }
  }

  function submitSwitchOrderRequest() {
    setIsConfirmLoading(true);
    httpPost(`OrderModule/v1.0/totsco/requests/${otsOrder?.id}/switch-order-request`, {
      properties: {
        PlannedSwitchDate: getProperty(
          getFirstRelation(installWorkOrder, 'ServiceAppointment'),
          'Date',
        ),
      },
    })
      .then((res) => {
        console.log('res: ', res);
        setIsPolling(true);
      })
      .catch((err) => {
        console.error('Error:', err?.response?.data);
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }

  function submitSwitchOrderUpdateRequest() {
    setIsConfirmLoading(true);
    httpPost(`OrderModule/v1.0/totsco/requests/${otsOrder?.id}/switch-update-request`, {
      properties: {
        PlannedSwitchDate: getProperty(
          getFirstRelation(installWorkOrder, 'ServiceAppointment'),
          'Date',
        ),
      },
    })
      .then((res) => {
        console.log('res: ', res);
        setIsPolling(true);
      })
      .catch((err) => {
        console.error('Error:', err?.response?.data);
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }

  function submitSwitchOrderTriggerRequest() {
    setIsConfirmLoading(true);
    httpPost(`OrderModule/v1.0/totsco/requests/${otsOrder?.id}/switch-trigger-request`, {
      properties: {
        ActivationDate: getProperty(record, 'ActiveDate'),
      },
    })
      .then((res) => {
        console.log('res: ', res);
        setIsPolling(true);
      })
      .catch((err) => {
        console.error('Error:', err);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }

  function submitSwitchOrderCancelRequest() {
    setIsConfirmLoading(true);
    httpPost(`OrderModule/v1.0/totsco/requests/${otsOrder?.id}/switch-cancel-request`, {})
      .then((res) => {
        console.log('res: ', res);
        setIsPolling(true);
      })
      .catch((err) => {
        console.error('Error:', err?.response?.data);
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setIsConfirmLoading(false);
      });
  }

  function resetState() {
    setError(undefined);
    setStepData({
      accountNumber: undefined,
      switchInternet: true,
      switchVoice: false,
      authorizeSwitchRequest: false,
      surname: undefined,
      email: undefined,
      phoneNumberToPort: undefined,
    });
    setContact(undefined);
    setAddress(undefined);
    setInstallWorkOrder(undefined);
    setOtsProviderDirectory(undefined);
    setVoiceConfiguration(undefined);
    setOtsOrder(undefined);
    setIsConfirmLoading(false);

    fetchOtsOrder();
  }

  console.log('stepData: ', stepData);
  console.log('isNextDisabled: ', isNextDisabled);
  console.log('otsOrder', otsOrder);
  console.log('isConfirmLoading', isConfirmLoading);

  return (
    <>
      <Button
        intent="primary"
        outlined
        onClick={() => {
          setIsDialogOpen(true);
        }}
        disabled={props.isDisabled}
      >
        {otsOrder ? 'Manage OTS Order' : 'Create OTS Order'}
      </Button>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-ots-order"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          resetState();
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          console.log('newStep: ', newStep);
          setupStep(newStep);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'One touch switch'}
      >
        <DialogStep
          id="step-one"
          title="Submit request to losing provider"
          panel={
            <Section>
              {stepError && (
                <Callout intent={stepError ? 'danger' : 'primary'}>{stepError}</Callout>
              )}
              {otsOrder &&
              !['SWITCH_MATCH_REQUEST_SENT', 'SWITCH_MATCH_REQUEST_FAILED'].includes(
                getProperty(otsOrder, 'Status'),
              ) ? (
                <SectionCard>
                  <div>
                    <div>
                      <h4>Actions</h4>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Button
                          loading={isConfirmLoading}
                          disabled={[
                            'SWITCH_MATCH_REQUEST_CONFIRMED',
                            'SWITCH_ORDER_REQUEST_CONFIRMED',
                            'SWITCH_TRIGGER_REQUEST_CONFIRMED',
                            'SWITCH_ORDER_TRIGGER_CONFIRMED',
                            'SWITCH_ORDER_CANCEL_CONFIRMED',
                          ].includes(getProperty(otsOrder, 'Status'))}
                          intent={'primary'}
                          onClick={() => submitSwitchOrderRequest()}
                        >
                          Submit Switch Order Request
                        </Button>
                        <Button
                          loading={isConfirmLoading}
                          style={{ marginLeft: '10px' }}
                          disabled={[
                            'SWITCH_MATCH_REQUEST_CONFIRMED',
                            'SWITCH_ORDER_REQUEST_CONFIRMED',
                            'SWITCH_TRIGGER_REQUEST_CONFIRMED',
                            'SWITCH_ORDER_TRIGGER_CONFIRMED',
                            'SWITCH_ORDER_CANCEL_CONFIRMED',
                          ].includes(getProperty(otsOrder, 'Status'))}
                          onClick={() => submitSwitchOrderRequest()}
                        >
                          Submit Switch Order Request
                        </Button>
                        <Button
                          loading={isConfirmLoading}
                          style={{ marginLeft: '10px' }}
                          intent={'primary'}
                          disabled={[
                            'SWITCH_ORDER_TRIGGER_CONFIRMED',
                            'SWITCH_ORDER_CANCEL_CONFIRMED',
                          ].includes(getProperty(otsOrder, 'Status'))}
                          onClick={() => submitSwitchOrderUpdateRequest()}
                        >
                          Submit Switch Update Request
                        </Button>
                        <Button
                          loading={isConfirmLoading}
                          style={{ marginLeft: '10px' }}
                          disabled={!record?.stage?.isActive}
                          intent={'success'}
                          onClick={() => submitSwitchOrderTriggerRequest()}
                        >
                          Submit Switch Trigger Request
                        </Button>
                        <Button
                          loading={isConfirmLoading}
                          style={{ marginLeft: '10px' }}
                          disabled={!record?.stage?.isFail}
                          intent={'danger'}
                          onClick={() => submitSwitchOrderCancelRequest()}
                        >
                          Submit Switch Cancel Request
                        </Button>
                      </div>
                    </div>
                    <div>
                      <h4>OTS Order Details</h4>
                      <Descriptions column={1}>
                        <Descriptions.Item label="OTS Order #">
                          {otsOrder?.recordNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="OTS Order Status">
                          {getProperty(otsOrder, 'Status')}
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions column={1}>
                        {getProperty(otsOrder, 'Services')?.map((elem: any) => (
                          <Descriptions.Item label={elem.serviceType}>
                            {elem.switchAction}
                          </Descriptions.Item>
                        ))}
                      </Descriptions>
                    </div>
                    <div>
                      <h4>Message Activity</h4>
                      {otsOrder?.ActivityLog?.dbRecords?.map((elem: any, index: number) => (
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label={`message ${dayjs(elem.createdAt).format('D/M HH:MM:ss')}`}
                          >
                            {elem?.title}
                          </Descriptions.Item>
                        </Descriptions>
                      ))}
                    </div>
                  </div>
                </SectionCard>
              ) : (
                <>
                  <SectionCard>
                    {stepError ? (
                      <Callout intent={stepError ? 'danger' : 'primary'}>{stepError}</Callout>
                    ) : (
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          <h3>Select losing provider</h3>
                        </div>
                        <div>
                          <Select
                            aria-required={true}
                            defaultValue={stepData?.otsProviderId}
                            style={{ width: 300 }}
                            onSelect={(elem) =>
                              setStepData({
                                ...stepData,
                                otsProviderId: elem,
                              })
                            }
                          >
                            {otsProviderDirectory?.map((elem: any) => (
                              <Select.Option value={elem.id}>{elem.name}</Select.Option>
                            ))}
                            ;
                          </Select>

                          <div>
                            <input
                              style={{ height: '32px', width: '300px', marginTop: '15px' }}
                              type="text"
                              placeholder="Account number (optional)"
                              value={stepData.accountNumber}
                              onChange={(elem) =>
                                setStepData({
                                  ...stepData,
                                  accountNumber: elem.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </SectionCard>

                  <SectionCard>
                    <div style={{ marginBottom: 10 }}>
                      <h3>Select services to switch</h3>
                    </div>
                    <Switch
                      required={true}
                      defaultChecked={stepData?.switchInternet}
                      onChange={(elem) => {
                        setStepData({
                          ...stepData,
                          switchInternet: elem.target.checked,
                        });
                      }}
                      labelElement={'Switch Internet'}
                    />
                    {voiceConfiguration && (
                      <Switch
                        defaultChecked={stepData?.switchVoice}
                        onChange={(elem) => {
                          setStepData({
                            ...stepData,
                            switchVoice: elem.target.checked,
                          });
                        }}
                        labelElement={'Switch Voice'}
                      />
                    )}

                    {stepData.switchVoice && (
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          <h5>Phone number to port</h5>
                        </div>
                        <div>
                          <input
                            style={{ height: '32px', width: '300px' }}
                            type="text"
                            value={stepData.phoneNumberToPort}
                            onChange={(elem) =>
                              setStepData({
                                ...stepData,
                                phoneNumberToPort: elem.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </SectionCard>
                </>
              )}
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="Confirm switch request"
          panel={
            <Section>
              <SectionCard>
                <Descriptions layout={'horizontal'} column={1} size={'small'}>
                  <Descriptions.Item label="Switching From">
                    {
                      otsProviderDirectory?.find((elem: any) => elem.id === stepData?.otsProviderId)
                        ?.name
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label="Losing provider Account #">
                    {stepData.accountNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address">
                    {getProperty(address, 'FullAddress')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Surname">
                    {getProperty(contact, 'LastName')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {getProperty(contact, 'EmailAddress')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Planned switch date">
                    {getProperty(getFirstRelation(installWorkOrder, 'ServiceAppointment'), 'Date')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Switching Internet">
                    {stepData?.switchInternet ? 'YES' : 'NO'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Switching Voice">
                    {stepData?.switchVoice ? 'YES' : 'NO'}
                  </Descriptions.Item>
                  {stepData.switchVoice && (
                    <Descriptions.Item label="Phone number to port">
                      {stepData.phoneNumberToPort}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </SectionCard>

              <SectionCard>
                <div style={{ marginBottom: 10 }}>
                  <h3>Customer authorized swithc request</h3>
                </div>
                <Switch
                  defaultValue={'false'}
                  checked={Boolean(stepData?.authorizeSwitchRequest) || false}
                  onChange={(elem) => {
                    setStepData({
                      ...stepData,
                      authorizeSwitchRequest: elem.target.checked,
                    });
                  }}
                  labelElement={'Authorized Switch Request'}
                />
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {!isConfirmLoading ? (
                <SectionCard>
                  {!otsOrder ? (
                    <Descriptions layout={'horizontal'} column={1} size={'small'}>
                      <Descriptions.Item label="Switching From">
                        {
                          otsProviderDirectory?.find(
                            (elem: any) => elem.id === stepData?.otsProviderId,
                          )?.name
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="Losing provider Account #">
                        {stepData.accountNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {getProperty(address, 'FullAddress')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Surname">
                        {getProperty(contact, 'LastName')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">
                        {getProperty(contact, 'EmailAddress')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Planned switch date">
                        {getProperty(
                          getFirstRelation(installWorkOrder, 'ServiceAppointment'),
                          'Date',
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Switching Internet">
                        {stepData?.switchInternet ? 'YES' : 'NO'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Switching Voice">
                        {stepData?.switchVoice ? 'YES' : 'NO'}
                      </Descriptions.Item>
                      {stepData.switchVoice && (
                        <Descriptions.Item label="Phone number to port">
                          {stepData.phoneNumberToPort}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  ) : (
                    <div>
                      <div>
                        <h4>Actions</h4>
                        <Button
                          disabled={
                            ![
                              'SWITCH_MATCH_REQUEST_CONFIRMED',
                              'SWITCH_MATCH_REQUEST_SENT',
                              'SWITCH_MATCH_REQUEST_FAILED',
                            ].includes(getProperty(otsOrder, 'Status')) || !isConfirmLoading
                          }
                          intent={'primary'}
                          onClick={() => submitSwitchOrderRequest()}
                        >
                          Submit Switch Order Request
                        </Button>
                      </div>
                      <div>
                        <h4>OTS Order Details</h4>
                        <Descriptions column={1}>
                          <Descriptions.Item label="OTS Order #">
                            {otsOrder?.recordNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="OTS Order Status">
                            {getProperty(otsOrder, 'Status')}
                          </Descriptions.Item>
                        </Descriptions>
                        <Descriptions column={1}>
                          {getProperty(otsOrder, 'Services')?.map((elem: any) => (
                            <Descriptions.Item label={elem.serviceType}>
                              {elem.switchAction}
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </div>
                      <div>
                        <h4>Message Activity</h4>
                        {otsOrder?.ActivityLog?.dbRecords?.map((elem: any, index: number) => (
                          <Descriptions column={1}>
                            <Descriptions.Item
                              label={`message ${dayjs(elem.createdAt).format('D/M HH:MM:ss')}`}
                            >
                              {elem?.title}
                            </Descriptions.Item>
                          </Descriptions>
                        ))}
                      </div>
                    </div>
                  )}
                </SectionCard>
              ) : (
                <SectionCard>
                  <Callout intent="primary">
                    Request submitted to the losing provider, waiting for a response...
                  </Callout>
                </SectionCard>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CreateOneTouchSwitchOrder);
